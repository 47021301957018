import React from 'react'
import {Layout, InsurancesCarousel} from 'widgets'
import {ImgEscritorio, Search, Conversation, Check, Platforms, Airplane} from 'images'
import {Image, SEO, Icon} from 'components'
import {WHATSAPP_LINK, WHATSAPP_PHONE} from 'utils'

const AboutUs = () => {
    const misionItems = [
        {
            icon: Search,
            text: 'Facilitar la búsqueda del seguro que requieren los distintos viajeros que desean explorar el mundo.'
        },
        {
            icon: Conversation,
            text: 'Brindar asesoría a la medida de cada persona que pueda tener dudas al momento de elegir y comprar su servicio.'
        },
        {
            icon: Check,
            text: 'Ofrecer variedad de proveedores debidamente seleccionados, para que solo elijan entre los principales y mejores competidores del mercado.'
        },
        {
            icon: Platforms,
            text: 'Crear una plataforma que entregue información clasificada de forma ágil y clara, para que nuestros viajeros tengan la libertad de sacarle provecho de la mejor forma.'
        },
    ]
    return(
        <Layout>
            <SEO
                title="Somos Compara Assist ¡Tu comparador de seguros de salud y viaje!"
                description="Nuestra plataforma te muestra las distintas opciones de seguros de salud internacionales y de viaje ¡Compara y decide lo mejor para ti! Conócenos"
                keywords="Quienes somos, comparador de seguros, compañías y aliados, proveedores, el servicio que ofrecemos,"
            />
            <div className="about-us">
                <div
                    className="header-banner"
                    style={{
                        backgroundImage: `url(${ImgEscritorio})`
                    }}
                />
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-xl-6 col-sm-12 text-center">
                        <div className="container model-image-container">
                            <Image
                                uri="model.png"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-xl-6 col-12 d-flex justify-content-center text-model-description">
                        <p className="text-dark-gray">
                            Somos el portal web que te permite comparar entre una amplia gama de aliados prestadores de seguros de salud internacionales y de viaje, para que puedas encontrar y comprar fácilmente tu opción ideal. Nuestra diversidad de precios y coberturas te permitirá acceder a las mejores ofertas en un solo lugar.
                        </p>
                    </div>
                </div>
                <div className="our-mission text-center text-white">
                    <h3 className="font-weight-normal">Nuestra misión</h3>
                    <div className="row">
                        {
                            misionItems.map((element, i) => {
                                return(
                                    <div
                                        className="col-md-3 col-sm-12 card-container"
                                        key={i}
                                    >
                                        <div className="container">
                                            <div className="image-container">
                                                <img
                                                    src={element.icon}
                                                    alt={'Search insurance'}
                                                />
                                            </div>
                                            <div className="card">
                                                <div
                                                    className="card-body text-justify"
                                                >
                                                    <p
                                                        className="text-dark-gray"
                                                    >
                                                        {element.text}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="our-vision bg-light-gray-two text-center">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h3 className="text-purple mt-2 font-weight-normal mt-1">Visión</h3>
                            <p className="text-dark-gray">
                                Ser el referente idóneo de los comparadores en latinoamérica a la hora de ubicar un seguro o asistencia en viaje
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h3 className="text-purple mt-2 font-weight-normal mt-1">
                                ¿Cuáles son nuestros proveedores?
                            </h3>
                            <p className="text-dark-gray">
                                Trabajamos con diferentes aliados multiculturales, que representan las compañías más calificadas en el mercado en cuanto a los seguros y/o asistencias de salud y de viaje a nivel internacional, con el objetivo de tener un estándar de calidad para quienes nos eligen. Conócelos:
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel bg-white p-2">
                    <InsurancesCarousel
                        withoutTitle
                        slidesMd={3}
                    />
                </div>
                <div className="bg-light-gray-two info">
                    <div className="text-center whatsapp-info p-2">
                        <div className="container text-center">
                            <div
                                className="info"
                            >
                                <h3 className="text-pink">
                                    ¿Quieres conocer más sobre nosotros?
                                </h3>
                                <a
                                    href={WHATSAPP_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="text-dark-gray font-weight-bold">
                                        <span
                                            className="whatsapp-icon"
                                        >
                                            <Icon
                                                name="whatsapp"
                                            />
                                        </span>
                                        {WHATSAPP_PHONE}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-info row w-100">
                    <div className="col-md-4 col-sm-6 image-container">
                        <img
                            src={Airplane}
                            alt={'Search insurance'}
                        />
                    </div>
                    <div className="col-md-8 col-sm-6 info-container">
                        <p className="text-dark-gray">
                            Trabajamos para que vivas una experiencia de compra clara y amigable que inicia desde nuestro portal web, se mantiene en nuestra atención personalizada y finaliza hasta saber que tienes tus soportes, así todos encontraran y tendrán al alcance de un click lo que están buscando.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs;